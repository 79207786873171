import React from 'react'
import Layout from '../../components/Layout'
import SEO from "../../components/seo"

export default () => (
  <Layout>
    <SEO title="Sign up for the Getlocal solution!"
          />
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Kærar þakkir!</h1>
          <p>Við verðum í sambandi fljótlega varðandi næstu skref.</p>
        </div>
      </div>
    </section>

    <script
      key={`body`}
      id="___gatsby"
        dangerouslySetInnerHTML={{
          __html: `

          fbq('track', 'Lead', {
            value: 1000,
            currency: 'EUR',
          });
          `,
      }}
      />


  </Layout>
)
